<template>
  <div v-if="store.deliveryOptions" class="delivery-interval-form">
    <div class="delivery-interval-form__label">Lieferintervall</div>
    <component
      :is="store.isTablet ? 'BaseClickBox' : 'BaseSelect'"
      v-model="deliveryInterval"
      :class="{ 'base-click-box--large': store.isTablet }"
      :options="store.deliveryOptions.intervals.filter((i) => !i.is_interval_pg51)"
      label=""
      name="changeBox"
      :disabled="hasCase()"
    />
    <SubmitChange
      api-path="delivery-options"
      :disabled="isButtonDisabled()"
      case-key="delivery_interval"
      :parent-data="getCaseData()"
      :has-case="hasCase()"
      @close="$emit('close')"
    />
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { ref } from 'vue'

const store = useStore()

const deliveryInterval = ref()

deliveryInterval.value = store.nextRegularBox.delivery_interval.slug ?? 'paused'

function getCaseData() {
  return {
    deliveryOptions: {
      interval: !['paused'].includes(deliveryInterval.value) ? deliveryInterval.value : null,
      paused: deliveryInterval.value === 'paused'
    }
  }
}

function isButtonDisabled() {
  if (deliveryInterval.value === 'paused') {
    return !!store.nextRegularBox.paused
  }

  return deliveryInterval.value === store.nextRegularBox.delivery_interval.slug
}

function hasCase() {
  return (
    store.hasTerminationCase ||
    !!store.patient?.case?.delivery_interval?.box?.id ||
    store.patient?.case?.paused
  )
}
</script>
