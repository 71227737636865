<template>
  <div class="login-form">
    <PflegeInfotooltip>
      <img class="content__icon" src="@/assets/icon-info.svg" alt="info"/>
      <p class="content__text">Die curabox®-Kundennummer finden Sie auf unseren Schreiben oder oben rechts auf jedem
        Lieferschein.</p>
    </PflegeInfotooltip>
    <component
      v-bind="{ ...data }"
      v-for="(data, key) in formData"
      v-model="data.value"
      :is="data.component"
      :key="key"
      :hasSuccess="!data.hasError && isValid(key)"
      @focusin="clearErrors()"
      @keyup.enter="login()"
    ></component>
    <PflegeButton
      label="Anmelden"
      :loading="isLoading"
      :disabled="isDisabled()"
      @click="login()"
    ></PflegeButton>
  </div>
</template>

<script setup>
import formDataJson from '../schemas/loginDataSchema.json'
import {setErrors} from '@/mixins/formMixin'
import {inject, onUnmounted, reactive, ref, watch} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from '@/store/index'

const CUSTOMER_NUMBER_PREFIX = 'F-'

const store = useStore()
const router = useRouter()
const route = useRoute()
const formData = reactive(formDataJson)
const apiService = inject('apiService')

const isLoading = ref(false)

formData.birthdate.value = route.query.bd
formData.customerNumber.value = route.query.acc || CUSTOMER_NUMBER_PREFIX

onUnmounted(() => {
  formData.birthdate.value = ''
  formData.customerNumber.value = ''
})

function isDisabled() {
  return !isValidBirthdate() || !isValidCustomerNumber()
}

function isValidCustomerNumber() {
  return /^[fF]-[0-9]{6,7}$/.test(formData.customerNumber.value)
}

function isValidBirthdate() {
  const minDate = '1900-01-01'
  return !!formData.birthdate.value && formData.birthdate.value > minDate
}

function isValid(key) {
  switch (key) {
    case 'customerNumber':
      return isValidCustomerNumber()
    case 'birthdate':
      return isValidBirthdate()
    default:
      return true;
  }
}

async function login() {
  if (isDisabled()) {
    return
  }

  isLoading.value = true
  const response = await apiService.login({
    customerNumber: formData.customerNumber.value,
    birthdate: formData.birthdate.value
  })

  if (response.token) {
    await store.initialize(response.patient)
    await router.push('/dashboard')
  } else if (response.errors) {
    response.errors.reason
      ? (store.loginError = response.errors.reason)
      : setErrors(response.errors, formData)
  }

  isLoading.value = false
}

function clearErrors() {
  formData.birthdate.hasError = false
  formData.customerNumber.hasError = false
}

watch(formData, () => {
  if (!formData.customerNumber.value.startsWith(CUSTOMER_NUMBER_PREFIX)) {
    setTimeout(() => {
      formData.customerNumber.value = CUSTOMER_NUMBER_PREFIX
    })
  }
})
</script>
