<template>
  <div>
    <BackButton />
    <h3>Daten des ambulanten Pflegedienstes</h3>
    <div class="form">
      <OutpatientServiceTeaser :clickable="false" />
      <div class="form__input-wrapper form__contact-wrapper">
        <ChangeContact headline="Sie möchten die Angaben ändern?" />
      </div>
    </div>
  </div>
</template>
