<template>
  <div v-if="store.patient" class="dashboard">
    <div class="dashboard__header flex-column">
      <div>Hallo</div>
      <div class="dashboard__header__name">
        {{ `${store.patient.data.firstname} ${store.patient.data.lastname}` }}
      </div>
      <img class="dashboard__header__icon" src="@/assets/icon-curabox.svg" alt="curabox" />
    </div>
    <div class="dashboard__content">
      <div class="dashboard__column flex-column">
        <ProductsDashboard @click="onClick('/boxzusammenstellung', 'edit_boxzusammenstellung')" />
        <BoxOptions />
        <Pg51Teaser
          v-if="store.products"
          @click="onClick('/bettschutzeinlagen', 'edit_waschbare_bettschutzeinlagen')"
        />
      </div>
      <div class="dashboard__column flex-column">
        <DeliveryAddressTeaser @click="onClick('/versandadresse', 'edit_versandadresse')" />
        <PatientTeaser @click="onClick('/versicherteperson', 'edit_versicherte_person')" />
        <CarePersonTeaser @click="onClick('/pflegeperson', 'edit_pflegeperson')" />
        <OutpatientServiceTeaser @click="onClick('/pflegedienst', 'edit_pflegedienst')" />
      </div>
    </div>
    <DeliveryStop @click="onClick('/lieferung-beenden', 'lieferung_beenden', 'link-click')" />
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { useRouter } from 'vue-router'
import scrollHelper from '@/helper/ScrollHelper.js'

const router = useRouter()
const store = useStore()

if (store.defaultChangeDelivery) {
  scrollHelper.scrollToDelivery()
}

function onClick(link = 'button-click') {
  return router.push(`/curabox/aendern${link}`)
}
</script>
