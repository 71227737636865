<template>
  <div v-if="store.patient && store.products" class="product-selection">
    <BackButton class="product-selection__back-button" />
    <div class="product-selection__header">
      <h3>Ihre curabox</h3>
      <img class="product-selection__header__icon" src="@/assets/icon-curabox.svg" alt="curabox" />
    </div>
    <div :class="{ has_open_case: hasCase() }">
      <div class="form-wrapper">
        <Progressbar :total-price="showTotalPrice()" />
        <div class="product-selection__form flex-column">
          <Boxes v-if="store.boxes" />
          <ProductsProductSelection :error-keys="errorKeys" @resetError="resetError" />
        </div>
      </div>
      <SubmitChange
        api-path="products"
        :disabled="isSubmitDisabled()"
        case-key="products"
        :parent-data="getCaseData()"
        :has-case="hasCase()"
        @error="onError"
      />
    </div>
    <div
      v-if="showTotalPrice()"
      class="product-selection__form__asterisk"
      v-html="
        '* inkl. gesetzlicher MwSt.</br></br> Sie erhalten eine auf den Versicherten ausgestellte Rechnung ' +
        `in Höhe des erstattungsfähigen Betrags von maximal ${store.visualMaxPrice}€ und gehen in Vorleistung. ` +
        'Anschließend beantragen Sie die Erstattung der Kosten der curabox bei Ihrer Pflegekasse selbst. ' +
        'Die Rechnung ist grundsätzlich voll erstattungsfähig, wenn ein Pflegegrad vorliegt.'
      "
    ></div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { ref } from 'vue'

const store = useStore()
const errorKeys = ref([])

function showTotalPrice() {
  return store.productSelection.totalPrice > 0 && store.patient?.data.is_privately_insured
}

function isSubmitDisabled() {
  return store.productSelection.totalPrice < store.minPrice
}

function getCaseData() {
  if (store.productSelection.amounts) {
    const products = Object.keys(store.productSelection.amounts).map((salesforceId) => {
      return {
        salesforceId,
        amount: store.productSelection.amounts[salesforceId]
      }
    })

    return {
      products,
      glove_intolerances: store.productSelection.gloveIntolerances,
      glove_size: store.productSelection.gloveSize,
      allergy_existing: store.productSelection.gloveIntolerancesOptionsOpen
    }
  }
}

function hasCase() {
  return store.hasTerminationCase || !!store.patient?.case?.products?.length
}

function onError(errors) {
  errorKeys.value = Object.keys(errors)
}

function resetError(key) {
  errorKeys.value = errorKeys.value.filter((error) => error !== key)
}
</script>
