<template>
  <div class="news-ticker flex-row">
    <MarqueeText
      v-if="store.isNewsTickerVisible"
      :repeat="isPaused ? 1 : 3"
      :duration="20"
      :paused="isPaused"
      @click="onClick()"
    >
      <span class="news-ticker__text" v-html="text"></span>
    </MarqueeText>
    <img
      class="news-ticker__icon"
      src="@/assets/icon-close-white.svg"
      alt="schließen"
      @click="clickClose()"
    />
  </div>
</template>

<script setup>
import MarqueeText from 'vue-marquee-text-component'
import { onMounted, onUnmounted, ref } from 'vue'
import { useStore } from '@/store/index'

const store = useStore()

const props = defineProps({
  page: {
    type: String,
    required: true
  }
})
let text = ''
const isPaused = ref(true)

window.addEventListener('resize', setIsPaused())
onUnmounted(() => {
  window.removeEventListener('resize', setIsPaused())
})

onMounted(async () => {
  // todo - fix env import
  /* eslint-disable-next-line camelcase,no-undef */
  const note = await (
    await fetch(`${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/notes-banners`)
  ).json()
  const currentPageNote = note.find((element) => element.page === props.page)

  if (currentPageNote.status) {
    store.isNewsTickerVisible = true
    text = currentPageNote.bannerNote.replace(/<\/?[^>]+(>|$)/g, '')
    setTimeout(() => {
      setIsPaused()
    })
  }
})

function setIsPaused() {
  const text = document.querySelector('.marquee-text-text')
  const ticker = document.querySelector('.news-ticker')

  isPaused.value = text && ticker ? text.offsetWidth + 30 < ticker.offsetWidth : false
}

function onClick() {
  window.open('https://curabox.de/faq', '_blank')
}

function clickClose() {
  store.isNewsTickerVisible = false
}
</script>
