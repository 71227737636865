<template>
  <div class="back-button flex-row" @click="onClick()">
    <img class="back-button__icon icon" src="@/assets/icon-arrowback.svg" alt="zurück" />
    <div class="back-button__text">Übersicht</div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

function onClick() {
  router.push('/dashboard')
}
</script>
