<template>
  <div class="box-options">
    <div :class="['box-options__tabs', 'flex-row', { 'box-options__tabs--open': openOption }]">
      <div
        v-for="(option, key) in options"
        :key="key"
        :class="['option', { 'option--active': openOption === key }]"
        @click="onClick(key)"
      >
        <div class="option__header flex-row">
          <span>{{ option.headline }}</span>
          <img v-if="openOption !== key" src="@/assets/icon-edit.svg" alt="bearbeiten" />
          <img v-else src="@/assets/icon-close.svg" alt="schließen" />
        </div>
        <div class="option__value">
          <span v-if="option.valuePrefix" class="option__value__prefix">
            {{ option.valuePrefix }}
          </span>
          <span>{{ option.value }}</span>
        </div>
      </div>
    </div>
    <div
      :class="[
        'box-options__form',
        {
          'box-options__form--visible': !hideForm && openOption,
          'box-options__form--visible-overflow': formOverflowVisible
        }
      ]"
    >
      <div class="box-options__form__wrapper">
        <GloveSizeForm
          v-if="openOption === 'gloveSize'"
          @close="onClick('gloveSize', 'close_handschuhe')"
        />
        <DeliveryIntervalForm
          v-if="openOption === 'deliveryInterval'"
          @close="onClick('deliveryInterval', 'close_lieferung')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { ref, reactive } from 'vue'

const store = useStore()

const openOptionTimeout = ref()
const overflowVisibleTimeout = ref()
const hideForm = ref(false)
const openOption = ref()
const formOverflowVisible = ref(false)

const options = reactive({
  gloveSize: {
    headline: 'Handschuhe',
    valuePrefix: 'Größe',
    value: null
  },
  deliveryInterval: {
    headline: 'Lieferung',
    value: null
  }
})

if (store.defaultChangeDelivery) {
  openOption.value = 'deliveryInterval'
  store.defaultChangeDelivery = false
}

options.gloveSize.value = store.patient.glove_size?.name
if (!options.gloveSize.value) {
  delete options.gloveSize
}

options.deliveryInterval.value = store.nextRegularBox.delivery_interval.name ?? 'pausiert'

function onClick(key) {
  window.clearTimeout(openOptionTimeout)
  window.clearTimeout(overflowVisibleTimeout)

  hideForm.value = openOption.value === key
  if (hideForm.value) {
    formOverflowVisible.value = false
    openOptionTimeout.value = setTimeout(() => {
      openOption.value = null
    }, 800)
  } else {
    openOption.value = key
    overflowVisibleTimeout.value = setTimeout(() => {
      formOverflowVisible.value = true
    }, 800)
  }
}
</script>
