<template>
  <div class="dashboard-teaser">
    <TeaserHeader :editable="!hasCase" headline="Versicherte Person" />
    <div class="dashboard-teaser__content clickable">
      <ContactData
        :header="`${patientData.firstname} ${patientData.lastname}`"
        :data="[patientData.phone, patientData.email]"
      />
      <CasesHint v-if="hasCase" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/store/index'
import ContactData from '@/components/dashboard/teaser/ContactData.vue'

const store = useStore()

const hasCase = !!store.patient?.case?.patients_data

const patientData = computed(() => store.patient.data)
</script>
