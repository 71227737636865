<template>
  <div :class="el">
    <div v-if="label" :class="`${el}__label`" v-html="label"></div>
    <div :class="`${el}__wrapper`">
      <div
        :class="[`${el}__option`, { [`${el}__option--selected`]: modelValue === options[0].value }]"
        @click="onToggle(options[0])"
      >
        <span>{{ options[0].label }}</span>
      </div>
      <div
        :class="[`${el}__option`, { [`${el}__option--selected`]: modelValue === options[1].value }]"
        @click="onToggle(options[1])"
      >
        <span>{{ options[1].label }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
const el = 'toggle-selection'

const props = defineProps({
  modelValue: {
    type: [Boolean, String]
  },
  label: {
    type: String,
    required: false
  },
  options: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

function onToggle(option) {
  emit('update:modelValue', option.value)
}
</script>
