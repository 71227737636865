<template>
  <div v-if="store.patient" class="pg51-teaser flex-row clickable">
    <img
      class="pg51-teaser__image"
      :src="`${pg51Product.image}.png`"
      :srcset="`${pg51Product.image}.png 1x, ${pg51Product.image}@2x.png 2x`"
      :alt="pg51Product.name"
    />
    <div class="flex-column">
      <div class="pg51-teaser__name">{{ pg51Product.name }}</div>
      <div :class="['pg51-teaser__status', `pg51-teaser__status--${getStatusColor()}`]">
        {{ getStatusText() }}
      </div>
    </div>
    <img class="pg51-teaser__icon" src="@/assets/icon-edit.svg" alt="bearbeiten" />
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { ref } from 'vue'

const store = useStore()

const pg51Product = ref(store.pg51Product)

const STATUS_APPROVED = 'approved'
const STATUS_DECLINED = 'declined'
const STATUS_REQUESTED = 'requested'

function getStatusText() {
  if (store.patient.approvedProducts.pg51?.expired) {
    return 'Genehmigung abgelaufen'
  }

  switch (store.patient.approvedProducts.pg51?.status) {
    case STATUS_REQUESTED:
      return 'in Bearbeitung bei Pflegekasse'
    case STATUS_APPROVED:
      return 'Genehmigung erteilt (in Belieferung)'
    case STATUS_DECLINED:
      return 'Antrag abgelehnt'
    default:
      return 'beantragbar'
  }
}

function getStatusColor() {
  if (
    !store.patient.approvedProducts.pg51 ||
    store.patient.approvedProducts.pg51.status === STATUS_REQUESTED
  ) {
    return 'yellow'
  }
  if (
    store.patient.approvedProducts.pg51.expired ||
    store.patient.approvedProducts.pg51.status === STATUS_DECLINED
  ) {
    return 'red'
  }
  return 'green'
}
</script>
