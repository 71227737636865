export default class ScrollHelper {
  static scrollToFirstError(className = 'invalid', offset = 140) {
    setTimeout(() => {
      const elements = document.querySelectorAll(`.${className}`)
      let top = 0
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect()
        if (rect.top < top) {
          top = rect.top
        }
      })

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      window.scroll({ top: top + scrollTop - offset, behavior: 'smooth' })
    }, 200)
  }

  static scrollToTop(behavior = 'smooth') {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: behavior
      })
    }, 50)
  }

  static scrollElementToFirstError(id) {
    setTimeout(() => {
      const element = document.getElementById(id)
      const errors = document.querySelectorAll('.invalid')
      let top = errors[0].offsetTop
      errors.forEach((element) => {
        top = Math.min(element.offsetTop, top)
      })

      element.scrollTo({
        top: top - 20,
        behavior: 'smooth'
      })
    }, 50)
  }

  static scrollToDelivery() {
    this.scrollToTop('auto')
    setTimeout(() => {
      const element = document.querySelector('.box-options')
      const rect = element.getBoundingClientRect()
      window.scrollTo({
        top: rect.top - 120,
        behavior: 'smooth'
      })
    }, 50)
  }
}
