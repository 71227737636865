<template>
  <div class="products">
    <h3>Produktübersicht</h3>
    <div class="products__wrapper">
      <div
        v-for="product in store.products"
        v-show="!product.isPG51"
        :key="product.id"
        class="product flex-row"
      >
        <div class="product__info flex-row">
          <img
            class="product__image"
            :src="`${product.image}.png`"
            :srcset="`${product.image}.png 1x, ${product.image}@2x.png 2x`"
            :alt="product.name"
          />
          <div class="flex-column">
            <span class="product__name">{{ product.name }}</span>
            <span class="product__size">{{ `${product.packageSize} ${product.unit}` }}</span>
          </div>
        </div>

        <div v-if="isProductApproved(product)" class="product__amount flex-row">
          <ProductAmountButton :product="product" />
          <span class="product__amount__number">{{ getAmount(product) }}</span>
          <ProductAmountButton :product="product" :is-plus="true" />
        </div>

        <div v-else class="product__hint flex-row">
          <img class="product__hint__icon icon" src="@/assets/icon-supportdanger.svg" alt="" />
          <div
            v-html="
              'Aktuell durch Ihre Pflegekasse nicht genehmigt.</br> Rufen Sie uns unter 040 - 8740 9910 an.'
            "
          ></div>
        </div>
        <GloveOptions
          v-if="showGloveOptions(product)"
          :error-keys="errorKeys"
          @resetError="onError"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useStore } from '@/store/index'
import ProductAmountButton from '@/components/product-selection/ProductAmountButton.vue'

const emit = defineEmits(['resetError'])

const store = useStore()

const props = defineProps({
  errorKeys: {
    type: Array
  }
})

initProducts()

function initProducts() {
  if (store.patient && store.products) {
    store.resetProductSelectionAmounts()
    store.productSelection.gloveSize = store.gloveSize?.id

    const boxProducts =
      (store.patient.case?.products?.length > 1 && store.patient.case?.products) ||
      store.nextRegularBox.products

    store.products.forEach((product) => {
      const boxProduct = boxProducts.find((p) => p.salesforceId === product.salesforceId)
      if (boxProduct) {
        store.productSelection.amounts[product.salesforceId] = boxProduct.amount
      }
    })
    store.updateTotalPrice()
  }
}

function getAmount(product) {
  return store.productSelection.amounts[product.salesforceId]
}

function isProductApproved(product) {
  return store.patient?.approvedProducts?.pg54?.includes(product.salesforceId)
}

function showGloveOptions(product) {
  return isProductApproved(product) && product.isGlove
}

function onError(key) {
  emit('resetError', key)
}

watch(
  () => store.patient,
  () => initProducts()
)
watch(
  () => store.products,
  () => initProducts()
)
</script>
