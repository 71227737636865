<template>
  <div
    v-if="store.terminationReasons && store.patient"
    id="delivery-stop-form"
    class="form delivery-stop-form"
    :class="{ has_open_case: store.hasTerminationCase }"
  >
    <div class="form__input-wrapper delivery-stop-form_checkbox">
      <BaseCheckbox
        v-model="isChecked"
        label="Ich möchte die Lieferung beenden"
        name="termination"
      />
    </div>
    <div class="termination" :class="{ disabled: !isChecked }">
      <TerminationReasons
        :is-checked="isChecked"
        :reasons="store.terminationReasons"
        @setValidData="isButtonDisabled($event)"
        @setTerminationReason="deliveryOptions = $event"
        @deliveryButtonClick="changeDeliveryInterval"
      />
    </div>
    <SubmitChange
      api-path="delivery-options"
      case-key="termination_reason"
      :disabled="disabledButton"
      :parent-data="getCaseData()"
      :has-case="store.hasTerminationCase"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'

const router = useRouter()
const store = useStore()

const isChecked = ref(false)
const disabledButton = ref(true)
const deliveryOptions = ref({})

function getCaseData() {
  return {
    deliveryOptions: {
      terminationReason: deliveryOptions.value?.terminationReason,
      subReason: deliveryOptions.value?.subReason
    }
  }
}

function isButtonDisabled(event) {
  //eslint-disable-next-line no-underscore-dangle
  disabledButton.value = !event._value || !isChecked.value
}

function changeDeliveryInterval() {
  store.defaultChangeDelivery = true
  router.push('/dashboard')
}
</script>
