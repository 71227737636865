<template>
  <div v-if="pg51Product && store.patient" class="pg51 form">
    <div class="form__input-wrapper">
      <div class="pg51__header flex-row">
        <img
          class="pg51__image"
          :src="`${pg51Product.image}-large.png`"
          :srcset="`${pg51Product.image}-large.png 1x, ${pg51Product.image}-large@2x.png 2x`"
          :alt="pg51Product.name"
        />
        <div>
          <div class="pg51__price">
            <span class="pg51__price__big">{{ isPrivatelyInsured() ? crossedPrice : '0,00' }}</span>
            <span class="pg51__price__small">€</span>
            <span class="pg51__price__smaller">/Stk</span>
          </div>
          <div v-if="!isPrivatelyInsured()" class="pg51__price--crossed-out">
            <b>{{ crossedPrice }}</b>
            €
          </div>
        </div>
      </div>

      <div class="pg51__info flex-row">
        <img
          class="icon pg51__info__icon"
          :src="`/images/icons/icon-supportinfo-20-x-20.svg`"
          alt="info"
        />
        <span class="pg51__info__text">
          {{ pg51.hint[isPrivatelyInsured() ? 'private' : 'legally'] }}
        </span>
      </div>

      <div
        v-if="status && !isPrivatelyInsured()"
        :class="['pg51__status', 'flex-row', `pg51__status--${status.type}`]"
      >
        <img
          class="icon pg51__status__icon"
          :src="`/images/icons/${status.icon}.svg`"
          :alt="status.icon"
        />
        <div class="pg51__status__text" v-html="getReplacedText(status.text)"></div>
      </div>

      <div v-if="status && isPrivatelyInsured()" class="pg51__order-details">
        <div class="pg51__headline">Bestelldetails</div>
        <div v-if="status.hint" class="pg51__order-details__hint flex-row">
          <img class="icon" src="@/assets/icon-checkmark.svg" alt="checkmark" />
          <span v-html="status.hint" />
        </div>
        <div class="pg51__order-details__text" v-html="getReplacedText(status.text)"></div>
        <ChangeContact v-if="status.showContact" headline="Sie möchten die Lieferung ändern?" />
      </div>

      <div v-if="isDownloadVisible()" class="pg51__download">
        <BaseButton
          class="pg51__download__button"
          label="Antrag herunterladen"
          icon-before="icon-download"
          @click="download()"
        />
        <div class="pg51__download__text" v-html="pg51.downloadHint"></div>
      </div>

      <div class="pg51__details">
        <div class="pg51__headline">Produktdetails:</div>
        <div v-for="(detail, index) in pg51.details" :key="index" class="pg51__details__row">
          {{ detail }}
        </div>
      </div>
      <div v-if="isPrivatelyInsured()" class="pg51__asterisk">{{ pg51.asteriskPrivate }}</div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { inject, watch, ref } from 'vue'
import pg51 from '@/ressources/pg51.json'

const store = useStore()

const apiService = inject('apiService')
const window = inject('window')

let { pg51Product } = store
const crossedPrice = pg51Product?.price?.toString().replace('.', ',')
const status = ref({})

setStatus()

function getStatusKey() {
  const pg51Data = store.patient?.approvedProducts.pg51
  if (!pg51Data) {
    return null
  }

  if (isPrivatelyInsured()) {
    return isYearlyDelivery() ? 'yearly' : 'oneTime'
  }

  if (pg51Data.status === 'approved') {
    if (pg51Data.expired) {
      return 'expired'
    }

    return pg51Data.approved_until ? 'temporary' : 'unlimited'
  }

  return pg51Data.status
}

function setStatus() {
  status.value = pg51.status[getStatusKey()]
}

function getReplacedText(text) {
  return text
    .replace('APPROVED_UNTIL', getApprovedUntil())
    .replace('APPROVED_UNTIL_MINUS_TWO_MONTHS', getApprovedUntil(-2))
    .replace('AMOUNT', getAmount())
    .replace('DELIVERY_INTERVAL', getDeliveryInterval())
    .replace('NEXT_DELIVERY', getNextDeliveryDate())
}

function isPrivatelyInsured() {
  return store.patient?.data.is_privately_insured
}

function download() {
  apiService.download('PdfBedLayer', `Antrag_Bettschutzeinlage`)
}

function getAmount() {
  return store.patient.pg51_order?.pg51_product_amount
}

function getDeliveryInterval() {
  return store.patient.pg51_order?.delivery_interval?.name
}

function isYearlyDelivery() {
  return store.patient.pg51_order?.delivery_interval?.slug === 'yearly'
}

function getApprovedUntil(offset = 0) {
  const date = new Date(store.patient?.approvedProducts.pg51?.approved_until)
  date.setMonth(date.getMonth() + offset)
  return getFormattedDate(date)
}

function getNextDeliveryDate() {
  return getFormattedDate(new Date(store.patient?.pg51_order?.delivery_date))
}

function getFormattedDate(date) {
  return date?.toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })
}

function isDownloadVisible() {
  return !status.value || status.value.type === 'error'
}

watch(
  () => store.products,
  () => (pg51Product = store.pg51Product)
)
watch(
  () => store.patient,
  () => setStatus()
)
</script>
