<template>
  <div class="dashboard-teaser">
    <TeaserHeader :editable="!hasCase" headline="Versandadresse" />
    <div class="dashboard-teaser__content clickable">
      <ContactData
        v-if="deliveryAddress.street_and_housenr"
        :header="
          deliveryAddress.company_name || `${deliveryAddress.firstname} ${deliveryAddress.lastname}`
        "
        :data="[
          deliveryAddress.street_and_housenr,
          deliveryAddress.address_supplement,
          `${deliveryAddress.zip} ${deliveryAddress.city}`
        ]"
      />
      <span v-else>Bitte geben Sie die Versandadresse an.</span>
      <CasesHint v-if="hasCase" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/store/index'
import ContactData from '@/components/dashboard/teaser/ContactData.vue'

const store = useStore()

const hasCase = !!store.patient?.case?.delivery_address

const deliveryAddress = computed(() => store.patient.delivery_address)
</script>
