<template>
  <div
    v-if="store.gloveOptions"
    :class="[
      'glove-options',
      'flex-column',
      { 'glove-options--visible': isVisible, 'glove-options--visible-overflow': isVisibleDelayed }
    ]"
  >
    <div class="glove-options_wrapper">
      <BaseClickBox
        v-model="store.productSelection.gloveSize"
        :class="['glove-size', { invalid: props.errorKeys.includes('glove_size') }]"
        label="Welche Handschuhgröße haben Sie?"
        :options="store.gloveOptions.sizes"
        :info-tooltip="{
          content: TOOLTIP_CONTENT
        }"
        :disabled="hasGloveSizeCase"
      />
      <CasesHint v-if="hasGloveSizeCase" />
      <BaseClickBox
        v-model="intolerancesOpen"
        :class="[
          'intolerances-check',
          { 'intolerances-check--visible': store.productSelection.gloveSize }
        ]"
        label="Hat die versicherte Person oder der Träger der Handschuhe eine Allergie / Unverträglichkeit gegen übliche Materialien: Latex, Vinyl, Nitril?"
        :options="intolerancesCheckOptions"
      />
    </div>
    <div class="glove-intolerances flex-column">
      <BaseClickBox
        v-model="store.productSelection.gloveIntolerances"
        :class="[
          'glove-intolerances__options',
          { 'glove-intolerances__options--visible': intolerancesOpen },
          { invalid: props.errorKeys.includes('glove_intolerances') }
        ]"
        :options="store.gloveOptions.materials"
        :multi="true"
        label="Gegen welches Material liegt eine Allergie / Unverträglichkeit vor?"
        @update:modelValue="onIntolerancesInput"
      />
      <div
        :class="[
          'glove-intolerances__warning',
          { 'glove-intolerances__warning--visible': showIntolerancesWarning }
        ]"
      >
        <div class="glove-intolerances__warning__text">
          Wenn Sie gegen alle drei Materialien eine Allergie haben, können wir Ihnen keine
          Einmalhandschuhe anbieten.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from '@/store/index'

const store = useStore()

const emit = defineEmits(['resetError'])

const props = defineProps({
  errorKeys: {
    type: Array
  }
})

const gloveAmount = computed(() => {
  return store.productSelection.amounts[store.glovesSalesforceId]
})
const hasGloveSizeCase = computed(() => {
  return !!store.patient?.case?.glove_size
})

const isVisible = ref(false)
const isVisibleDelayed = ref(false)
const intolerancesOpen = ref(false)
const showIntolerancesWarning = ref(false)
let timeout = null

const TOOLTIP_CONTENT =
  'Unsere empfohlene Handschuhgröße ist M. Diese passt dem Großteil der Menschen. ' +
  'Um die geeignete Handschuhgröße zu ermitteln, können Sie folgenden Leitfaden nutzen: ' +
  "<a href='https://curabox.de/curabox/handschuhe-messen.pdf' target='_blank'>Handschuhgröße ermitteln</a>"

store.productSelection.gloveSize = store.gloveSize.id

const intolerancesCheckOptions = [
  { name: 'Ja', value: true },
  { name: 'Nein', value: false }
]

const intolerances = store.patient.case
  ? store.patient.case.glove_intolerances
  : store.patient.glove_intolerances

store.productSelection.gloveIntolerances = intolerances
  ? intolerances.map((i) => {
      return i.id
    })
  : []

changeVisibility()

function onIntolerancesInput() {
  showIntolerancesWarning.value = store.productSelection.gloveIntolerances.length === 3

  if (showIntolerancesWarning.value) {
    store.productSelection.gloveIntolerances.pop()
  }
}

function changeVisibility() {
  window.clearTimeout(timeout)
  isVisible.value = !!gloveAmount.value
  if (!isVisible.value) {
    hideIntolerances()
    if (!hasGloveSizeCase.value) {
      store.productSelection.gloveSize = null
    }
    intolerancesOpen.value = null
    isVisibleDelayed.value = false
  } else {
    timeout = setTimeout(() => {
      isVisibleDelayed.value = true
    }, 1000)
  }
}

function hideIntolerances() {
  store.productSelection.gloveIntolerances = []
  showIntolerancesWarning.value = false
}

watch(gloveAmount, () => {
  changeVisibility()
})

watch(intolerancesOpen, () => {
  store.productSelection.gloveIntolerancesOptionsOpen = intolerancesOpen.value
  if (!intolerancesOpen.value) {
    hideIntolerances()
  }
})

watch(
  () => store.productSelection.gloveSize,
  () => {
    emit('resetError', 'glove_size')
  }
)
watch(
  () => store.productSelection.gloveIntolerances,
  () => {
    emit('resetError', 'glove_intolerances')
  },
  { deep: true }
)
</script>
