<template>
  <div v-if="showOverlay" :class="el">
    <div :class="`${el}__background`"></div>
    <div :id="`${el}__layer`" :class="`${el}__layer`">
      <span :class="`${el}__layer__headline`">Sie werden gleich abgemeldet</span>
      <span :class="`${el}__layer__timer`">{{ timer }}</span>
      <span :class="`${el}__layer__info`">
        Zu Ihrer Sicherheit melden wir sie gleich automatisch ab. Sofern sie angemeldet bleiben
        wollen, klicken Sie auf “Angemeldet bleiben”
      </span>
      <BaseButton label="Angemeldet bleiben" @click="onStayLoggedInButtonClick" />
      <BaseButton label="Jetzt abmelden" :secondary="true" @click="onLogoutButtonClick" />
    </div>
  </div>
</template>

<script setup>
import { inject, onBeforeMount, onUnmounted, ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'

const el = 'auto-logout'

const apiService = inject('apiService')

const store = useStore()
const router = useRouter()

const EVENTS = ['mousemove', 'mousedown', 'scroll', 'keydown', 'touchstart', 'touchmove']
const USER_ACTIVITY_THROTTLER_TIME = 1000
const INACTIVE_USER_TIME_THRESHOLD = 15 * 60 * 1000
const LOGOUT_COUNTDOWN_START = 30

const userActivityTimeout = ref(null)
const userActivityThrottlerTimeout = ref(null)
const logoutCountdown = ref(0)

const showOverlay = ref(false)

const timer = computed(() => `00:${logoutCountdown.value < 10 ? '0' : ''}${logoutCountdown.value}`)

onBeforeMount(() => {
  EVENTS.forEach((event) => {
    window.addEventListener(event, userActivityThrottler)
  })
  resetUserActivityTimeout()
})

onUnmounted(() => {
  EVENTS.forEach((event) => {
    window.removeEventListener(event, userActivityThrottler)
  })
})

function resetUserActivityTimeout() {
  clearTimeout(userActivityTimeout.value)
  userActivityTimeout.value = setTimeout(() => {
    onInactivity()
  }, INACTIVE_USER_TIME_THRESHOLD)
}

function userActivityThrottler() {
  if (!userActivityThrottlerTimeout.value && !showOverlay.value) {
    userActivityThrottlerTimeout.value = setTimeout(() => {
      resetUserActivityTimeout()
      clearTimeout(userActivityThrottlerTimeout.value)
      userActivityThrottlerTimeout.value = null
    }, USER_ACTIVITY_THROTTLER_TIME)
  }
}

function onInactivity() {
  showOverlay.value = true
  logoutCountdown.value = LOGOUT_COUNTDOWN_START
}

function onStayLoggedInButtonClick() {
  showOverlay.value = false
  resetUserActivityTimeout()
}

function onLogoutButtonClick() {
  logout()
}

function logout() {
  apiService.logout()
  showOverlay.value = false
  store.patient = null
  router.push('/login')
}

function countdown() {
  setTimeout(() => {
    logoutCountdown.value -= 1
  }, 1000)
}

watch(showOverlay, () => {
  document.documentElement.style.overflow = showOverlay.value ? 'hidden' : 'auto'
  if (showOverlay.value) {
    clearTimeout(userActivityTimeout.value)
    clearTimeout(userActivityThrottlerTimeout.value)
    userActivityThrottlerTimeout.value = null
  }
})

watch(logoutCountdown, () => {
  if (showOverlay.value) {
    if (logoutCountdown.value > 0) {
      countdown()
    } else {
      logout()
    }
  }
})
</script>
