<template>
  <div v-if="store.patient" class="form" :class="{ has_open_case: hasCase }">
    <div class="form-wrapper">
      <div v-for="(form, index) in formData" :key="index">
        <div class="form__headline">{{ form.headline }}</div>
        <div class="form__input-wrapper">
          <component
            v-bind="{ ...data }"
            :is="data.component"
            v-for="(data, key) in form.data"
            v-show="key === 'opt_phone' ? isOptPhone : true"
            :key="key"
            v-model="data.value"
            tabindex="0"
            :name="key"
            :hide-success-icon="true"
            :default-values="defaultValues"
            :error-keys="lastErrorKeys"
            @focusout="isFieldHidden(key)"
            @blur="['phone', 'opt_phone'].includes(key) ? onPhoneNumberBlur(data) : null"
            @input="
              ['phone', 'opt_phone'].includes(key) ? onPhoneNumberInput(data) : $emit('input', key)
            "
            @change="onChange(data, key)"
            @addAdditionalFormData="addAdditionalFormData"
          />
        </div>
      </div>
    </div>
    <SubmitChange
      :api-path="apiPath"
      :case-key="caseKey"
      :disabled="disableSubmit"
      :form-data-email="caseKey === 'patients_data' ? formData?.[1]?.data?.email?.value : null"
      :parent-data="getCaseData()"
      :has-case="hasCase"
      @error="onError"
    />
  </div>
</template>

<script setup>
import { watch, onMounted, ref, reactive, computed } from 'vue'
import {
  onPhoneNumberBlur,
  onPhoneNumberInput,
  setErrors,
  resetErrors,
  isValidPhoneNumber
} from '@/mixins/formMixin'
import { useStore } from '@/store/index'

const props = defineProps({
  formData: {
    type: Array,
    required: true
  },
  defaultValues: {
    type: Object
  },
  apiPath: {
    type: String,
    required: true
  },
  caseKey: {
    type: String,
    required: false
  },
  hasCase: {
    type: Boolean,
    default: false
  }
})

const store = useStore()
const isOptPhone = ref(false)
const additionalFormData = reactive({})
const lastErrorKeys = ref([])
const disableSubmit = ref(true)

const phoneNumber = computed(
  () => props.formData[0].data.phone?.value || props.formData[1].data.phone?.value
)

onMounted(() => {
  setDefaultValues()
  resetErrors(props.formData)
})

/* eslint-disable no-param-reassign */
function setDefaultValues() {
  if (props.defaultValues) {
    Object.keys(props.defaultValues).forEach((key) => {
      props.formData.forEach((form) => {
        if (form.data[key]) {
          form.data[key].value = props.defaultValues[key]
        }
      })
    })
  }
  isFieldHidden('phone')
}

function getCaseData() {
  const data = {}
  props.formData.forEach((form) => {
    Object.keys(form.data).forEach((key) => {
      data[key] = form.data[key].value
    })
  })
  return { ...data, ...additionalFormData }
}

function addAdditionalFormData(key, value) {
  lastErrorKeys.value = []
  additionalFormData[key] = value
}

function isFieldHidden(key) {
  if (key === 'phone' && phoneNumber.value) {
    isOptPhone.value = isValidPhoneNumber(phoneNumber.value)
  }
}

function onError(errors) {
  setErrors(errors, props.formData)
  lastErrorKeys.value = Object.keys(errors)
}

function onChange(data, key) {
  data.hasError = false
  isFieldHidden(key)
  disableSubmit.value = false
}

watch(
  () => props.defaultValues,
  () => {
    setDefaultValues()
  }
)
</script>
