<template>
  <div v-if="store.gloveOptions" class="glove-size-form">
    <BaseClickBox
      v-model="gloveSize"
      :options="store.gloveOptions.sizes"
      label="Handschuhgröße"
      :disabled="hasCase()"
    />
    <SubmitChange
      api-path="glove-size"
      :disabled="isDisabled()"
      case-key="glove_size"
      :parent-data="getCaseData()"
      :has-case="hasCase()"
      @close="$emit('close')"
    />
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { ref } from 'vue'

const store = useStore()

const gloveSize = ref(store.gloveSize.id)

function isDisabled() {
  return gloveSize.value === store.patient.glove_size?.id
}

function getCaseData() {
  return {
    salesforceId: store.glovesSalesforceId,
    gloveSize: gloveSize.value
  }
}

function hasCase() {
  return store.hasTerminationCase || !!store.patient?.case?.glove_size
}
</script>
