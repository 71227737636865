<template>
  <div class="eligible-for-aid-selection">
    <span v-if="!isCheckboxOpen" class="eligible-for-aid-selection__link" @click="onLinkClick()">
      Ist die versicherte Person beihilfeberechtigt?
    </span>
    <div v-else>
      <BaseCheckbox
        v-model="props.modelValue"
        label="Die versicherte Person ist beihilfeberechtigt."
        :name="name"
        @update:modelValue="$emit('update:modelValue', $event)"
      ></BaseCheckbox>
      <div class="eligible-for-aid-selection__info">
        <span class="eligible-for-aid-selection__info__headline">
          Was ist Beihilfe und wer ist beihilfeberechtigt?
        </span>
        <span class="eligible-for-aid-selection__info__text">
          Beihilfe, auch als beamtenrechtliche Krankenfürsorge bekannt, ist ein staatlicher Zuschuss
          für Beamte zu ihren Gesundheitskosten. Durch den Zuschuss werden mindestens 50% der
          Gesundheitskosten gedeckt. Beihilfeberechtigt sind demnach Beamte, ihre Ehe- oder
          Lebenspartner und ihre Kinder.
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  name: {
    type: String
  }
})

const isCheckboxOpen = ref(props.modelValue)

function onLinkClick() {
  isCheckboxOpen.value = true
}

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      isCheckboxOpen.value = true
    }
  }
)
</script>
