<template>
  <div :class="el">
    <div :class="`${el}__header`">{{ header }}</div>
    <div v-for="(datum, index) in data" v-show="datum" :key="index">{{ datum }}</div>
  </div>
</template>

<script setup>
const el = 'dashboard-teaser__contact-data'

const props = defineProps({
  header: {
    type: String,
    required: true
  },
  data: {
    type: Array,
    required: true
  }
})
</script>
