<template>
  <div>
    <BackButton />
    <h3>Daten des Versicherten</h3>
    <BaseChangeForm
      api-path="patients-data"
      :form-data="formData"
      :default-values="getDefaultData()"
      case-key="patients_data"
      :has-case="hasCase()"
    />
  </div>
</template>

<script setup>
import formDataJson from '@/schemas/patientDataSchema.json'
import { useStore } from '@/store/index'
import { reactive } from 'vue'

const store = useStore()
const formData = reactive(formDataJson)

function getDefaultData() {
  return store.patient?.case?.patients_data || store.patient?.data
}

function hasCase() {
  return store.hasTerminationCase || !!store.patient?.case?.patients_data
}
</script>
