<template>
  <div class="products">
    <TeaserHeader :editable="!hasCase" headline="Ihre curabox" />
    <div v-if="store.patient.status === 'candidate'" class="candidate-tooltip">
      <h4>Genehmigung ausstehend</h4>
      <InfoTooltip
        content="Bitte beachten Sie, dass Ihre Pflegekasse ihren Antrag bisher nicht genehmigt hat. Gegebenenfalls ändert sich die Zusammenstellung nochmal und sie können nicht alle beantragten Produkte empfangen."
      />
    </div>
    <div class="products__wrapper clickable" :class="{ case: hasCase }">
      <div
        v-for="product in store.products?.filter((p) => isProductVisible(p))"
        :key="product.id"
        :class="['product', 'flex-row']"
      >
        <div class="product__amount">{{ getProductAmount(product) }}</div>
        <img
          class="product__image"
          :src="`${product.image}.png`"
          :srcset="`${product.image}.png 1x, ${product.image}@2x.png 2x`"
          :alt="product.name"
        />
        <div class="flex-column">
          <span class="product__name">{{ product.name }}</span>
          <span class="product__size">{{ `${product.packageSize} ${product.unit}` }}</span>
        </div>
      </div>
      <CasesHint v-if="hasCase" />
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'

const store = useStore()

const hasCase = !!store.patient?.case?.products?.length

function isProductVisible(product) {
  return !product.isPG51 && getProductAmount(product)
}

function getProductAmount(product) {
  const patientProduct = store.nextRegularBox.products.find((p) => {
    return p.salesforceId === product.salesforceId
  })

  return patientProduct?.amount
}
</script>
