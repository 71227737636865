<template>
  <div
    :class="[
      'my-header',
      {
        'my-header--with-news-ticker': store.isNewsTickerVisible
      }
    ]"
  >
    <img
      class="my-header__logo"
      src="@/assets/meine-curabox_logo.svg"
      alt="meine-curabox"
    />
    <div class="my-header__logout-button">
      <img
        v-if="store.patient"
        src="@/assets/icon-logout.svg"
        alt="logout"
        @click="logout"
      />
    </div>
  </div>
</template>

<script setup>
import {useStore} from '@/store/index'
import {useRouter} from 'vue-router'
import {inject} from 'vue'

const router = useRouter()
const store = useStore()

const apiService = inject('apiService')

function logout() {
  apiService.logout()
  store.patient = null
  router.push('/login')
}
</script>
