<template>
  <div :class="el">
    <div :class="`${el}__insurance`">
      <div>{{ isPrivatelyInsured ? 'privat versichert' : 'gesetzlich versichert' }}</div>
      <div>{{ defaultValues.insurance_company }}</div>
      <div>{{ defaultValues.insurance_number }}</div>
    </div>
    <BaseButton
      label="Versicherung ändern"
      :class="`${el}__layer-btn`"
      :secondary="true"
      @click="isOpen = !isOpen"
    />
    <div v-if="isOpen">
      <h3 :class="`${el}__headline`">So wechseln Sie Ihre Pflegekasse:</h3>
      <div :class="`${el}__step`" v-html="schema.steps[1]"></div>
      <component
        :is="data.component"
        v-for="(data, key) in schema.data"
        :key="key"
        v-bind="{ ...data }"
        v-model="data.value"
        :name="key"
        :hide-success-icon="true"
        @update:modelValue="onChange(key)"
      />
      <div :class="`${el}__step`" v-html="schema.steps[2]"></div>
      <BaseButton
        label="Antrag herunterladen"
        class="health-insurance__download-btn"
        :disabled="!downloadEnabled"
        :loading="isLoading"
        @click="downloadPDF()"
      />
      <div v-for="i in 3" :class="`${el}__step`" v-html="schema.steps[i + 2]"></div>
      <div :class="`${el}__contact`">
        <div :class="`${el}__contact__text`">
          <img class="icon" src="@/assets/icon-supportinfo.svg" alt="" />
          Für Fragen stehen wir Ihnen gerne zur Verfügung unter:
        </div>
        <div :class="`${el}__contact__information`">
          <div :class="`${el}__contact__information__phone`">
            <img class="icon" src="@/assets/icon-hotline.svg" alt="phone" />
            <div :class="`${el}__contact__information__phone__details`">
              <span class="number">040 87409757</span>
              <span class="hours">Mo - Do 08:00 - 17:00 Uhr</span>
              <span class="hours">Fr 08:00 - 16:30 Uhr</span>
            </div>
          </div>
          <div :class="`${el}__contact__information__email`">
            <img class="icon" src="@/assets/icon-email.svg" alt="email" />
            <span class="email-address">
              <a href="mailto:curabox@pflege.de">curabox@pflege.de</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, ref, watch } from 'vue'
import { useStore } from '@/store/index'
import { resetErrors } from '@/mixins/formMixin'
import schemaJson from '@/schemas/healthInsuranceDataSchema.json'
import Insurances from 'shared-components/src/json/insurances.json'

const el = 'health-insurance'

const store = useStore()
const apiService = inject('apiService')

const props = defineProps({
  defaultValues: {
    type: Object
  },
  errorKeys: {
    type: Array,
    default: []
  }
})

const isPrivatelyInsured = computed(() => {
  return store.patient.case?.patients_data
    ? store.patient.case?.patients_data.is_privately_insured
    : store.patient?.data.is_privately_insured
})

const newInsuranceCompanyData = computed(() => {
  return schema.data.insurance_company.values.find(
    (company) => company.label === schema.data.insurance_company.value
  )
})

const downloadEnabled = computed(() => {
  const regex = new RegExp('^[A-Za-z][0-9]{9}$')
  return (
    newInsuranceCompanyData.value &&
    (newInsuranceCompanyData.value.status === 'privat' ||
      regex.test(schema.data.insurance_number.value))
  )
})

const isOpen = ref(false)
const isLoading = ref(false)

const schema = reactive(schemaJson)
schema.data.insurance_company.values = [...Insurances.private, ...Insurances.legal]

const emit = defineEmits(['addAdditionalFormData'])
initData()

function initData() {
  emptySchemaData()
  setDefaultData()
}

function downloadPDF() {
  isLoading.value = true
  const params = {
    insurance_company: schema.data.insurance_company.value,
    insurance_number: schema.data.insurance_number.value,
    is_eligible_for_aid: schema.data.is_eligible_for_aid.value
  }

  apiService.download('PdfChangeHealthInsurance', 'Antrag_Curabox', params)
  setTimeout(() => {
    isLoading.value = false
  }, 2000)
}

function onChange(key) {
  schema.data[key].hasError = false
  emit('addAdditionalFormData', key, schema.data[key].value)

  if (key === 'insurance_company' && newInsuranceCompanyData.value) {
    if (newInsuranceCompanyData.value.status === 'privat') {
      schema.data.insurance_number.hasError = false
    }
    emit(
      'addAdditionalFormData',
      'is_privately_insured',
      newInsuranceCompanyData.value.status === 'privat'
    )
  }
}

function setDefaultData() {
  schema.data.is_eligible_for_aid.value = props.defaultValues.is_eligible_for_aid
  Object.keys(schema.data).forEach((key) => {
    emit('addAdditionalFormData', key, props.defaultValues[key])
  })
  emit('addAdditionalFormData', 'is_privately_insured', props.defaultValues.is_privately_insured)
}

function emptySchemaData() {
  resetErrors(schema.data)
  Object.keys(schema.data).forEach((key) => {
    schema.data[key].value = key === 'is_eligible_for_aid' ? false : ''
  })
}

watch(
  () => props.errorKeys,
  () => {
    props.errorKeys.forEach((key) => {
      if (key === 'insurance_company' || key === 'insurance_number') {
        schema.data[key].hasError = true
      }
    })
  }
)

watch(isOpen, () => {
  if (!isOpen.value) {
    initData()
  } else {
    Object.keys(schema.data).forEach((key) => {
      emit('addAdditionalFormData', key, schema.data[key].value)
    })
  }
})
</script>
