import {defineStore} from 'pinia'

export const useStore = defineStore('main', {
  state: () => {
    return {
      isMobile: false,
      isTablet: false,
      isNewsTickerVisible: false,
      patient: null,
      gloveOptions: null,
      deliveryOptions: null,
      terminationReasons: null,
      products: null,
      boxes: null,
      minPrice: 0,
      maxPrice: 0,
      visualMaxPrice: 0,
      productSelection: {
        totalPrice: 0,
        amounts: null,
        gloveSize: null,
        gloveIntolerances: [],
        gloveIntolerancesOptionsOpen: false
      },
      trackTeaser: true,
      defaultChangeDelivery: false,
      caseRequester: {
        isCarePerson: null,
        firstname: '',
        lastname: '',
        email: ''
      },
      loginError: null,
      nextRegularBox: {}
    }
  },

  getters: {
    glovesSalesforceId(state) {
      const product = state.products?.find((p) => p.isGlove)
      return product?.salesforceId
    },

    pg51Product(state) {
      return state.products?.find((p) => p.isPG51) || {}
    },

    gloveSize(state) {
      return state.patient?.case?.glove_size || state.patient?.glove_size
    },

    hasTerminationCase(state) {
      return !!state.patient?.case?.termination_reason
    }
  },

  actions: {
    initialize(patient) {
      this.patient = patient
      this.setnextRegularBox(patient)
      this.fetchGloveOptions()
      this.fetchDeliveryOptions()
      this.fetchAdminData()
      this.fetchTerminationReasons()
    },

    async fetchGloveOptions() {
      const response = await this.apiService.get('my/glove-options')
      this.gloveOptions = response.data
    },

    async fetchDeliveryOptions() {
      const response = await this.apiService.get('my/delivery-options')
      this.deliveryOptions = response.data
    },

    async fetchTerminationReasons() {
      const response = await this.apiService.get('api/termination-reasons')
      this.terminationReasons = response.data
    },

    async fetchAdminData() {
      /* eslint-disable */
      const products = await (
        await fetch(`${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/products`)
      ).json();

      const boxes = await (
        await fetch(`${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/boxes`)
      ).json();

      boxes.forEach((box) => {
        products.forEach((product) => {
          const boxProduct = box.products.find((p) => parseInt(p.id, 10) === product.id);
          if (boxProduct) {
            boxProduct.image = product.image;
            boxProduct.name = product.name;
            boxProduct.salesforceId = product.salesforceId;
            boxProduct.size = `${product.packageSize} ${product.unit}`;
            boxProduct.amount = parseInt(boxProduct.quantity, 10);
            delete boxProduct.quantity;
          }
        });
      });

      const prices = await (
        await fetch(`${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/prices`)
      ).json();

      this.products = products;

      this.resetProductSelectionAmounts();

      this.boxes = boxes;
      this.minPrice = prices.minPrice;
      this.maxPrice = prices.maxPrice;
      this.visualMaxPrice = prices.visualMaxPrice;
    },

    resetProductSelectionAmounts() {
      const amounts = {};
      this.products.forEach((p) => {
        if (!p.isPG51) {
          amounts[p.salesforceId] = 0;
        }
      });

      this.productSelection.amounts = amounts;
    },

    updateTotalPrice() {
      this.productSelection.totalPrice = this.products
        .filter((p) => {
          return !p.isPG51;
        })
        .map((p) => this.productSelection.amounts[p.salesforceId] * p.price)
        .reduce((a, b) => a + b);
    },

    setnextRegularBox(patient) {
      this.nextRegularBox = patient.orders.find((b) => b.delivery_interval.slug !== 'one_time');
    },
  },
});
