import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from '@/store'
import ScrollHelper from '@/helper/ScrollHelper'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import DeliveryStop from '@/views/DeliveryStop.vue'
import PatientData from '@/views/PatientData.vue'
import DeliveryAddress from '@/views/DeliveryAddress.vue'
import CarePerson from '@/views/CarePerson.vue'
import OutpatientService from '@/views/OutpatientService.vue'
import ProductSelection from '@/views/ProductSelection.vue'
import Pg51 from '@/views/Pg51.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/curabox/aendern/lieferung-beenden',
      name: 'lieferung_beenden',
      component: DeliveryStop
    },
    {
      path: '/curabox/aendern/versicherteperson',
      name: 'versicherte_person',
      component: PatientData
    },
    {
      path: '/curabox/aendern/versandadresse',
      name: 'versandadresse',
      component: DeliveryAddress
    },
    {
      path: '/curabox/aendern/pflegeperson',
      name: 'pflegeperson',
      component: CarePerson
    },
    {
      path: '/curabox/aendern/pflegedienst',
      name: 'pflegedienst',
      component: OutpatientService
    },
    {
      path: '/curabox/aendern/boxzusammenstellung',
      name: 'boxzusammenstellung',
      component: ProductSelection
    },
    {
      path: '/curabox/aendern/bettschutzeinlagen',
      name: 'bettschutzeinlagen',
      component: Pg51
    }
  ]
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    const store = useStore()
    const { query } = from
    if (
      store.caseRequester.email ||
      store.caseRequester.firstname ||
      store.caseRequester.lastname
    ) {
      delete query.ctype
      delete query.email
      delete query.cpfn
      delete query.cpln
      delete query.ipfn
      delete query.ipln
    }
    if (to.name !== 'Login') {
      delete query.acc
      delete query.bd
    }
    router.push({ name: to.name, query })
  } else {
    next()
  }
})

router.afterEach((to, from, next) => {
  ScrollHelper.scrollToTop()
})

export default router
