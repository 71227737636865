<template>
  <div :class="{ 'dashboard-teaser': isDashboardView }" @click="onClick">
    <TeaserHeader v-if="isDashboardView" :editable="editable" headline="Pflegedienst (optional)" />
    <div class="dashboard-teaser__content" :class="{ clickable: editable }">
      <ContactData
        v-if="outpatientService"
        :header="outpatientService.name"
        :data="[
          outpatientService.street_and_housenr,
          outpatientService.address_supplement,
          `${outpatientService.zip} ${outpatientService.city}`
        ]"
      />
      <div v-else>
        <span>Kein ambulanter Pflegedienst hinterlegt.</span>
        <ChangeContact />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store/index'
import ContactData from '@/components/dashboard/teaser/ContactData.vue'

const store = useStore()
const route = useRoute()

const props = defineProps({
  clickable: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['click'])

const isDashboardView = route.path === '/dashboard'
const editable = !!store.patient?.outpatient_service && props.clickable
const outpatientService = computed(() => store.patient?.outpatient_service)

function onClick() {
  if (editable) {
    emit('click', '/pflegedienst')
  }
}
</script>
