<template>
  <div
    id="progressbar"
    :class="[
      'progressbar',
      {
        'progressbar--with-news-ticker': store.isNewsTickerVisible,
        'progressbar--sticky': isSticky
      }
    ]"
  >
    <div class="progressbar-wrapper">
      <KProgress
        :percent="getPercent()"
        bg-color="rgba(0, 0, 0, 0.2)"
        :color="store.productSelection.totalPrice >= store.minPrice ? '#158000' : '#cc4444'"
        :show-text="false"
        :line-height="20"
        :class="{ 'k-progress--full': getPercent() >= 99 }"
      ></KProgress>
      <span
        v-if="!showCrossedOutPrice() && totalPrice"
        class="progressbar__price"
        :style="`width:${getPercent()}%`"
        v-html="`<b>${store.productSelection.totalPrice.toFixed(2)}</b>€`"
      ></span>
      <div
        v-if="showCrossedOutPrice()"
        class="progressbar__price-cross-out"
        v-html="`<b>${store.productSelection.totalPrice.toFixed(2)}</b>€`"
      ></div>
      <div class="progressbar__max-price" v-html="getVisualMaxPrice()"></div>
    </div>
    <div
      :class="[
        'flex-row',
        'progressbar__hint',
        { 'progressbar__hint--success': minPriceReached() }
      ]"
    >
      <span>
        {{
          minPriceReached()
            ? 'Der Mindestbetrag ist erreicht.'
            : 'Der Mindestbetrag ist noch nicht erreicht.'
        }}
      </span>
      <img class="progressbar__hint__icon" src="@/assets/icon-checkmark.svg" alt="" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useStore } from '@/store/index'

const store = useStore()

const props = defineProps({
  totalPrice: {
    type: Number
  }
})

const isSticky = ref(false)

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})

function getPercent() {
  return store.productSelection.totalPrice < store.visualMaxPrice
    ? store.productSelection.totalPrice * (100 / store.visualMaxPrice)
    : 100
}

function minPriceReached() {
  return store.productSelection.totalPrice >= store.minPrice
}

function onScroll() {
  const headerHeight = store.isNewsTickerVisible ? 95 : 55
  const progressbar = document.getElementById('progressbar')
  isSticky.value = progressbar && progressbar.getBoundingClientRect().top <= headerHeight
}

function showCrossedOutPrice() {
  return (
    store.patient?.data.is_privately_insured &&
    store.productSelection.totalPrice > store.visualMaxPrice
  )
}

function getVisualMaxPrice() {
  return `<b>${store.visualMaxPrice}</b>€${store.patient?.data.is_privately_insured ? '*' : ''}`
}
</script>
