<template>
  <div class="login">
    <h3>Willkommen im neuen Kundenbereich der curabox®!</h3>
    <div class="login__wrapper">
      <span class="login__info-text">
        Hier können Sie Ihre curabox® selbst verwalten, um zum Beispiel den Inhalt der Box oder persönliche Daten zu ändern.
        <br/>
        <br/>
        Dafür melden Sie sich einfach mit der Kundennummer und dem Geburtsdatum der pflegebedürftigen Person an.
      </span>
      <LoginForm/>
    </div>
  </div>
</template>
