/* eslint-disable no-param-reassign */
export function isValidPhoneNumber(input) {
  if (!input) {
    return true
  }
  const regex = new RegExp('^\\+49[1-9][0-9\\s]{4,}$')
  return regex.test(input)
}

export function onPhoneNumberBlur(data) {
  let ret = data.value
  ret = ret.replace(/\s/g, '')

  while (ret.indexOf('0') === 0) {
    ret = ret.replace('0', '')
  }
  while (ret.indexOf('+') !== -1) {
    ret = ret.replace('+', '')
  }
  if (ret.indexOf('49') === 0) {
    ret = `+${ret}`
  }
  if (ret.indexOf('+49') === -1) {
    ret = `+49${ret}`
  }

  while (ret.indexOf('0') === 3) {
    ret = ret.replace('0', '')
  }

  data.value = ret
}

export function onPhoneNumberInput(data) {
  data.value = data.value.length > 3 ? data.value : ''
}

export function setErrors(errors, formData) {
  resetErrors(formData)
  Object.keys(errors).forEach((key) => {
    if (Array.isArray(formData)) {
      formData.forEach((form) => {
        if (form.data[key]) {
          form.data[key].hasError = true
        }
      })
    } else {
      formData[key].hasError = true
    }
  })
}

export function resetErrors(formData) {
  if (Array.isArray(formData)) {
    formData.forEach((form) => {
      Object.keys(form.data).forEach((key) => {
        form.data[key].hasError = false
      })
    })
  } else {
    Object.keys(formData).forEach((key) => {
      formData[key].hasError = false
    })
  }
}
