<template>
  <div
    v-if="store.patient && options.length"
    class="form delivery-address"
    :class="{ has_open_case: hasCase() }"
  >
    <div class="form-wrapper">
      <div class="form__input-wrapper">
        <BaseClickBox
          v-model="newRecipient"
          :options="options"
          class="base-click-box--large base-click-box--large--wrap"
          label="Bitte Empfänger wählen"
          name="deliveryRecipient"
        />
      </div>
      <OutpatientServiceTeaser
        v-if="newRecipient === 'outpatient_service'"
        @click="goToOutpatientService"
      />
      <div v-else class="form__input-wrapper">
        <component
          v-bind="{ ...data }"
          :is="data.component"
          v-for="(data, key) in formData"
          v-show="!isFieldHidden(key)"
          :key="key"
          v-model="data.value"
          :name="key"
          :hide-success-icon="true"
          @change="onChange(data)"
        />
      </div>
    </div>
    <SubmitChange
      api-path="delivery-options"
      case-key="delivery_address"
      :disabled="buttonDisabled"
      :parent-data="getCaseData()"
      :has-case="hasCase()"
      @error="onError"
    />
  </div>
</template>

<script setup>
import { ref, watch, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import formDataJson from '@/schemas/deliveryAddressSchema.json'
import { setErrors, resetErrors } from '@/mixins/formMixin'
import OutpatientServiceTeaser from '@/components/dashboard/teaser/OutpatientServiceTeaser.vue'

const router = useRouter()
const store = useStore()

const formData = reactive(formDataJson)

const currentRecipient = ref()
const newRecipient = ref()
const options = ref([])
const buttonDisabled = ref(true)

const currentAddress = computed(
  () => store.patient?.case?.delivery_address || store.patient?.delivery_address || {}
)

setOptions()
setRecipient()
setDefaultValues()

function setOptions() {
  if (store.deliveryOptions) {
    store.deliveryOptions.recipients.forEach((e) => {
      if (e.slug !== 'other') {
        options.value.push({
          name: e.name.replace('/', '/ '),
          value: e.slug
        })
      }
    })
  }
}

function setRecipient() {
  currentRecipient.value =
    store.patient?.case?.delivery_address?.delivery_recipient.slug ||
    store.patient?.delivery_recipient?.slug

  newRecipient.value = currentRecipient.value
}

function setDefaultValues() {
  resetErrors(formData)
  Object.keys(formData).forEach((key) => {
    formData[key].value =
      currentRecipient.value === newRecipient.value ? currentAddress.value[key] : ''
  })
}

function getCaseData() {
  const data = {}
  Object.keys(formData).forEach((key) => {
    data[key] = formData[key].value
  })

  return {
    deliveryOptions: {
      address: {
        recipient: newRecipient.value,
        ...data
      }
    }
  }
}

function goToOutpatientService() {
  return router.push(`/curabox/aendern/pflegedienst`)
}

function hasCase() {
  return store.hasTerminationCase || !!store.patient?.case?.delivery_address
}

function isFieldHidden(key) {
  return newRecipient.value === 'insured_person' && ['firstname', 'lastname'].includes(key)
}

function onError(errors) {
  const formErrors = {}
  Object.keys(errors).forEach((key) => {
    formErrors[key.replace('deliveryOptions.address.', '')] = errors[key]
  })
  setErrors(formErrors, formData)
}

function onChange(data) {
  data.hasError = false
  buttonDisabled.value = false
}

watch(
  () => store.patient,
  () => {
    setRecipient()
    setDefaultValues()
  }
)

watch(
  () => store.deliveryOptions,
  () => setOptions()
)

watch(
  () => newRecipient.value,
  () => {
    setDefaultValues()
    buttonDisabled.value =
      newRecipient.value === currentRecipient.value ||
      (newRecipient.value === 'outpatient_service' && !store.patient?.outpatient_service)
  }
)
</script>
