<template>
  <div class="dashboard-teaser">
    <TeaserHeader :editable="!hasCase" :headline="headline" />
    <div class="dashboard-teaser__content clickable">
      <ContactData
        v-if="carePerson"
        :header="`${carePerson.firstname} ${carePerson.lastname}`"
        :data="[carePerson.phone, carePerson.email]"
      />
      <div v-else>
        Es sind keine Daten der Pflegeperson hinterlegt. Bitte geben Sie die Daten an.
      </div>
      <CasesHint v-if="hasCase" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/store/index'
import ContactData from '@/components/dashboard/teaser/ContactData.vue'

const store = useStore()

let hasCase = !!store.patient?.case?.care_person

const carePerson = computed(() => store.patient.care_person)

const headline = computed(() => `Pflegeperson ${ofLegalAge() ? '(optional)' : ''}`)

function ofLegalAge() {
  const birthdate = new Date(store.patient.data.birthdate)
  const today = new Date()
  let age = today.getFullYear() - birthdate.getFullYear()
  const m = today.getMonth() - birthdate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age -= 1
  }

  return age >= 18
}
</script>
