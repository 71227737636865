import axios from 'axios'
import * as qs from 'qs'
import { useStore } from '@/store'

class ApiService {
  constructor(
    tokenKey = 'my-user-token',
    baseURL = `${import.meta.env.VITE_APP_CURABOX_API_URL}/`
  ) {
    this.tokenKey = tokenKey
    this.baseURL = baseURL
    this.instance = axios.create({ baseURL })

    this.instance.interceptors.request.use(
      (config) => {
        const token = JSON.parse(localStorage.getItem(tokenKey))
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )
  }

  async get(url, params) {
    try {
      const response = await this.instance.get(url, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async post(url, data) {
    try {
      const response = await this.instance.post(url, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async patch(url, data) {
    try {
      const response = await this.instance.patch(url, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async delete(url, data) {
    try {
      const response = await this.instance.delete(url, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async login(data, url = `${this.baseURL}my/login`) {
    const responseData = await this.post(url, data)
    if (responseData.token) {
      localStorage.setItem(this.tokenKey, JSON.stringify(responseData.token))
    }

    return responseData
  }

  async logout(url = `${this.baseURL}my/logout`) {
    const response = await this.post(url)
    if (response) {
      localStorage.removeItem(this.tokenKey)
    }

    return response
  }

  async getLoggedInPatient() {
    const response = await this.get(`${this.baseURL}my/patient`)
    return response.patient
  }

  async download(url, fileName, params = {}) {
    const store = useStore()
    axios({
      url: `${this.baseURL}api/download/${url}`,
      params: {
        patientId: store.patient.id,
        ...params
      },
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem(this.tokenKey))}`
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}-${new Date().toLocaleDateString()}.pdf`)
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    })
  }
}

export default ApiService
