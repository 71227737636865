<template>
  <div v-if="store.patient" class="submit-change">
    <div class="submit-change__info">
      <div
        v-if="!hasCase"
        class="submit-change__info__hint"
        v-html="getHint(store.patient.status)"
      ></div>
      <div v-else>
        <span class="submit-change__headline">Änderungswunsch in Bearbeitung.</span>
        <span class="submit-change__message">
          Aktuell können keine weiteren Änderungen vorgenommen werden.
        </span>
      </div>
      <BaseButton
        tabindex="0"
        :label="buttonLabel"
        :disabled="!hasCase && disabled"
        :loading="!showOverlay && isLoading"
        :secondary="hasCase"
        @click="onButtonClick()"
      />
    </div>
    <SubmitChangeOverlay
      v-if="showOverlay"
      :consent="consent"
      :form-data-email="formDataEmail"
      :is-submitted="isSubmitted"
      :errors="errors"
      :loading="isLoading"
      @close="closeOverlay"
      @save="saveCase"
    />
  </div>
</template>

<script setup>
import { inject, ref, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getTimestamp } from 'shared-components/src/classes/DateTime'
import ScrollHelper from '@/helper/ScrollHelper'
import { useStore } from '@/store/index'
import SubmitChangeOverlay from '@/components/SubmitChangeOverlay.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()

const apiService = inject('apiService')

const emit = defineEmits(['close', 'error'])

const props = defineProps({
  apiPath: {
    type: String,
    required: true
  },
  caseKey: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  formDataEmail: {
    type: String
  },
  parentData: {
    type: Object
  },
  hasCase: {
    type: Boolean,
    default: false
  }
})

const showOverlay = ref(false)
const isSubmitted = ref(false)
const isLoading = ref(false)
const errors = ref(null)
const consent =
  '<span class="big">Ja,</span> ich stimme den ' +
  '<a class="link" target="_blank" href="https://www.pflege.de/datenschutz/">Datenschutzhinweisen</a> ' +
  'sowie der Verarbeitung meiner Daten zu Auftrags- und Marketingzwecken und der Kontaktaufnahme per ' +
  'E-Mail und Telefon zu. Ein <a class="link" href="mailto:betroffenenrechte@pflege.de">Widerruf</a> ' +
  'ist jederzeit möglich.'

const buttonLabel = computed(() => {
  if (props.hasCase) {
    return route.name === 'dashboard' ? 'Schließen' : 'Zur Übersicht'
  }
  return route.name === 'lieferung_beenden' ? 'Kündigung einreichen' : 'Änderungen einreichen'
})

window.onpopstate = function () {
  document.documentElement.style.overflow = 'auto'
}

function onButtonClick() {
  if (props.hasCase) {
    if (route.name !== 'dashboard') {
      router.push('/dashboard')
    } else {
      emit('close')
    }
  } else {
    saveCase(true)
  }
}

function closeOverlay() {
  showOverlay.value = false
}

async function saveCase(validate = false) {
  isLoading.value = true
  const response = await apiService.post(`my/cases/${props.apiPath}`, {
    validate,
    validateCaseRequester: !validate,
    ...props.parentData,
    optIn: getOptInData(),
    case_requester: getCaseRequesterData()
  })
  if (validate) {
    if (response.message === 'success') {
      showOverlay.value = true
    } else if (response.errors) {
      emit('error', response.errors)
      ScrollHelper.scrollToFirstError()
    }
  } else {
    if (response.message === 'success') {
      store.patient = await apiService.getLoggedInPatient()
      isSubmitted.value = true
    } else {
      errors.value = response.errors
    }
  }
  isLoading.value = false
}

function getOptInData() {
  return {
    name: `consent_meine-curabox_${props.caseKey}`,
    text: consent,
    timestamp: getTimestamp()
  }
}

function getCaseRequesterData() {
  return {
    is_care_person: store.caseRequester.isCarePerson,
    firstname: store.caseRequester.firstname,
    lastname: store.caseRequester.lastname,
    email: store.caseRequester.email
  }
}

function getHint(patientStatus) {
  if (patientStatus === 'candidate') {
    return 'Bitte beachten Sie, dass Ihre Pflegekasse ihren Antrag bisher nicht genehmigt hat. Sollten benötigte Produkte aus Ihrem Änderungswunsch nicht genehmigt werden, nehmen wir noch einmal mit Ihnen Kontakt auf.'
  }
  return 'Änderungen werden von uns so schnell wie möglich vorgenommen. Gegebenenfalls wird Ihre Änderung erst bei der übernächsten Lieferung vorgenommen.'
}

watch(showOverlay, () => {
  document.documentElement.style.overflow = showOverlay.value ? 'hidden' : 'auto'
})
</script>
