<template>
  <div v-if="availableBox" class="boxes">
    <h3>Für Sie empfohlen</h3>
    <div class="boxes__wrapper flex-column">
      <div class="boxes__products flex-row">
        <div v-for="product in availableBox.products" :key="product.id" class="product">
          <div class="product__amount flex-row">{{ product.amount }}</div>
          <img
            class="product__image"
            :src="`${product.image}.png`"
            :srcset="`${product.image}.png 1x, ${product.image}@2x.png 2x`"
            :alt="product.name"
          />
          <div class="product__name">{{ product.name }}</div>
          <div class="product__size">{{ product.size }}</div>
        </div>
      </div>
      <div class="boxes__select flex-row" @click="selectBox">
        <img class="boxes__select__icon icon" src="@/assets/icon-add.svg" alt="übernehmen" />
        <span>Auswahl übernehmen</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { watch } from 'vue'

const store = useStore()
let availableBox

setBox()

function setBox() {
  availableBox = store.boxes.find((box) =>
    box.products?.every((prod) => store.patient.approvedProducts?.pg54?.includes(prod.salesforceId))
  )
}

function selectBox() {
  store.resetProductSelectionAmounts()
  availableBox.products.forEach((p) => {
    store.productSelection.amounts[p.salesforceId] = p.amount
  })
  store.updateTotalPrice()
}

watch(
  () => store.boxes,
  () => setBox()
)
</script>
