<template>
  <div class="my-footer">
    <PflegeButton
      variant="text"
      label="Hilfe & Kontakt"
      iconPosition="left"
      icon="phone-line"
      href="https://www.pflege.de/kontakt/"
      target="_blank"
    ></PflegeButton>
    <PflegeDivider
      height="36px"
      borderColor="mint-dark"
    />
    <div class="my-footer__links">
      <a target="_blank" href="https://service.pflege.de/curabox/faq">Fragen und Antworten</a>
      <a target="_blank" href="https://www.pflege.de/datenschutz/">Datenschutz</a>
      <a target="_blank" href="https://www.pflege.de/agb/">AGB</a>
      <a target="_blank" href="https://www.pflege.de/impressum/">Impressum</a>
      <a target="_blank" href="https://www.pflege.de/hwg-pflichttexte" rel="nofollow">Pflichtangaben (HWG und
        Biozide)</a>
    </div>
  </div>
</template>
