<template>
  <div
    :class="[el, { [`${el}--disabled`]: isDisabled(), [`${el}--visible-hint`]: isHintVisible }]"
    @click="onClick()"
    @mouseleave="hideHint"
  >
    <div
      :id="HINT_ID"
      :class="[`${el}__hint`, { [`${el}__hint--visible`]: isHintVisible }]"
      :style="`transform: translate(${getTranslateX()})`"
    >
      {{ getHintText() }}
    </div>
    <img
      :class="`${el}__icon`"
      :src="`/images/icons/icon-${isPlus ? 'add' : 'remove'}.svg`"
      :alt="isPlus ? 'plus' : 'minus'"
    />
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { computed, ref } from 'vue'

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isPlus: {
    default: false
  }
})

const el = 'product-amount-button'
const HINT_ID = Math.random()
  .toString(36)
  .replace('0.', 'hint-' || '')
const HINT_PADDING = 5

const store = useStore()

const amount = computed(() => store.productSelection.amounts[props.product.salesforceId])
const isHintVisible = ref(false)

window.addEventListener('resize', hideHint)
window.addEventListener('scroll', hideHint)

function isDisabled() {
  return props.isPlus
    ? amount.value >= props.product.maxNo ||
        store.productSelection.totalPrice >= store.maxPrice - props.product.price
    : amount.value <= 0
}

function onClick() {
  if (isDisabled()) {
    isHintVisible.value = true
  } else {
    props.isPlus ? addProduct() : removeProduct()
  }
}

function addProduct() {
  store.productSelection.amounts[props.product.salesforceId] = Math.min(
    props.product.maxNo,
    amount.value + 1
  )
  store.updateTotalPrice()
}

function removeProduct() {
  store.productSelection.amounts[props.product.salesforceId] = Math.max(0, amount.value - 1)
  store.updateTotalPrice()
}

function getHintText() {
  return props.isPlus
    ? 'Ihr gesetzlicher Anspruch oder die zulässige Menge des Produktes ist bereits ausgereizt.'
    : 'Sie haben bereits die Menge des Produktes auf 0 reduziert.'
}

function getTranslateX() {
  if (!isHintVisible.value) {
    return
  }
  const rect = document.getElementById(HINT_ID)?.getBoundingClientRect()
  if (rect?.left < 0) {
    return `${rect.left * -1 + HINT_PADDING}px`
  }
  if (rect?.right > window.innerWidth) {
    return `${window.innerWidth - rect.right - HINT_PADDING}px`
  }
  return '0'
}

function hideHint() {
  isHintVisible.value = false
}
</script>
