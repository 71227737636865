<template>
  <div class="delivery-stop" :class="{ clickable: !showHint }" @click="onClick">
    <div class="delivery-stop__text" v-html="text"></div>
    <div v-if="!showHint" class="delivery-stop__link">Lieferung beenden</div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'

const store = useStore()

const emit = defineEmits(['click'])

let showHint = !!store.patient?.case?.termination_reason
let text = !showHint
  ? 'Sie möchten nicht mehr durch die curabox beliefert werden?'
  : "Sie haben die Kündigung eingereicht. Haben Sie Fragen oder Sie wollen dies rückgängig machen, kontaktieren Sie uns bitte unter: <span class='bold'>040 874 097 57</span>"

function onClick() {
  if (!showHint) {
    emit('click')
  }
}
</script>
