<template>
  <div>
    <BackButton />
    <h3>Daten des Angehörigen/Pflegeperson</h3>
    <BaseChangeForm
      api-path="care-person"
      :form-data="formData"
      :default-values="getDefaultData()"
      case-key="care_person"
      :has-case="hasCase()"
    />
  </div>
</template>

<script setup>
import formDataJson from '@/schemas/carePersonDataSchema.json'
import { useStore } from '@/store/index'
import { reactive } from 'vue'

const store = useStore()

const formData = reactive(formDataJson)

function getDefaultData() {
  return store.patient?.case?.care_person || store.patient?.care_person
}

function hasCase() {
  return store.hasTerminationCase || !!store.patient?.case?.care_person
}
</script>
