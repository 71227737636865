<template>
  <div :class="el">
    <div :class="`${el}__background`" @click="$emit('close')"></div>
    <div :class="`${el}__wrapper`">
      <div :id="`${el}__layer`" :class="`${el}__layer`">
        <div :class="`${el}__layer__headline`">{{ headline }}</div>
        <ToggleSelection
          v-if="!isSubmitted"
          v-model="store.caseRequester.isCarePerson"
          label="Wer sind Sie?"
          :options="REQUESTER_OPTIONS"
        />
        <div v-if="store.caseRequester.isCarePerson !== null && !isSubmitted">
          <div v-if="store.caseRequester.isCarePerson">
            <div :class="`${el}__layer__info`">Geben Sie bitte Ihre Daten an.</div>
            <BaseInput
              v-for="input in CARE_PERSON_INPUTS"
              v-model="store.caseRequester[input.key]"
              :label="input.label"
              :name="input.key"
              :hide-success-icon="true"
              :has-error="
                caseRequesterErrors && `case_requester.${input.key}` in caseRequesterErrors
              "
              @change="delete caseRequesterErrors[`case_requester.${input.key}`]"
            />
            <div v-if="insuredPersonEmail" :class="`${el}__layer__info`">
              Zusätzlich senden wir eine Bestätigung an die versicherte Person:
              <b>{{ `${store.patient.data.firstname} ${store.patient.data.lastname}` }}</b>
              <b>{{ insuredPersonEmail }}</b>
            </div>
          </div>

          <div v-else>
            <div :class="`${el}__layer__info`" v-html="insuredPersonInfoText"></div>
            <BaseInput
              v-if="!insuredPersonEmail"
              v-model="store.caseRequester.email"
              label="E-Mail-Adresse"
              name="ip_email"
              :hide-success-icon="true"
              :has-error="caseRequesterErrors && `case_requester.email` in caseRequesterErrors"
              @change="delete caseRequesterErrors['case_requester.email']"
            />
          </div>
        </div>

        <div v-if="isSubmitted">
          <img :class="`${el}__layer__checkmark`" src="@/assets/icon-checkmark-green.svg" alt="" />
          <div :class="`${el}__layer__info`">
            Wir senden eine E-Mail an:
            <b>{{ store.caseRequester.email }}</b>
            <b v-if="store.caseRequester.isCarePerson && insuredPersonEmail">
              {{ insuredPersonEmail }}
            </b>
            wenn wir Ihren Änderungswunsch bearbeitet haben. Bitte beachten Sie, dass dies bis zu
            fünf Tage in Anspruch nehmen kann.
          </div>
        </div>

        <BaseCheckbox v-if="!isSubmitted" v-model="isChecked" :label="consent" />
        <BaseButton
          :class="[`${el}__layer__button`, { [`${el}__layer__button--full-width`]: !isSubmitted }]"
          :label="buttonLabel"
          :disabled="isButtonDisabled"
          :loading="loading"
          :secondary="isSubmitted"
          @click="onButtonClick()"
        />
        <span
          v-if="!['dashboard', 'lieferung_beenden'].includes(route.name) && isSubmitted"
          :class="`${el}__layer__link`"
          @click="goToDashboard()"
        >
          zur Übersicht
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import ScrollHelper from '@/helper/ScrollHelper'
import ToggleSelection from '@/components/ToggleSelection.vue'

const props = defineProps({
  consent: {
    type: String,
    required: true
  },
  formDataEmail: {
    type: String
  },
  isSubmitted: {
    type: Boolean
  },
  loading: {
    type: Boolean
  },
  errors: {
    type: Object
  }
})

const emit = defineEmits(['save', 'close'])

const el = 'submit-change-overlay'
const store = useStore()
const route = useRoute()
const router = useRouter()

const isChecked = ref(false)
const caseRequesterErrors = ref(props.errors)

const buttonLabel = computed(() =>
  props.isSubmitted
    ? route.name === 'lieferung_beenden'
      ? 'zur Übersicht'
      : 'Schließen'
    : 'Absenden'
)

const insuredPersonEmail = computed(
  () => store.patient.data.email || store.patient.case?.patients_data?.email || props.formDataEmail
)

const isButtonDisabled = computed(
  () => !isChecked.value || store.caseRequester.isCarePerson === null
)

const insuredPersonInfoText = computed(() =>
  insuredPersonEmail.value
    ? `Eine Bestätigung der Änderung schicken wir Ihnen an:<b>${insuredPersonEmail.value}</b>`
    : `Geben Sie bitte Ihre E-Mail-Adresse an.<b>${store.patient.data.firstname} ${store.patient.data.lastname}</b>`
)

const headline = computed(() =>
  props.isSubmitted ? 'Ihr Änderungswunsch wurde eingereicht.' : 'Ihre Angaben zum Änderungswunsch'
)

const REQUESTER_OPTIONS = [
  {
    label: 'versicherte Person',
    value: false
  },
  {
    label: 'angehörige Person',
    value: true
  }
]

const CARE_PERSON_INPUTS = [
  {
    key: 'firstname',
    label: 'Vorname'
  },
  {
    key: 'lastname',
    label: 'Nachname'
  },
  {
    key: 'email',
    label: 'E-Mail-Adresse'
  }
]

function onButtonClick() {
  if (!props.isSubmitted) {
    emit('save')
  } else if (route.name === 'lieferung_beenden') {
    goToDashboard()
  } else {
    emit('close')
  }
}

function goToDashboard() {
  document.documentElement.style.overflow = 'auto'
  router.push('/dashboard')
}

watch(
  () => store.caseRequester.isCarePerson,
  () => {
    store.caseRequester.firstname = ''
    store.caseRequester.lastname = ''
    store.caseRequester.email = !store.caseRequester.isCarePerson ? insuredPersonEmail.value : ''
    caseRequesterErrors.value = null
  }
)

watch(
  () => props.errors,
  () => {
    caseRequesterErrors.value = props.errors
    ScrollHelper.scrollElementToFirstError(`${el}__layer`)
  }
)
</script>
